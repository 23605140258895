<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Newsletter bearbeiten</strong> #{{ $route.params.id }}
            </slot>
          </CCardHeader>
          <CCardBody>
            <NewsletterArticleForm
                v-if="ready"
                @submit="editArticle"
                @sendTestEmail="sendTestEmail"
                :enable-delete="true"
                :passedData="article"
                :processing="processing"
                btn-text="Änderungen speichern"/>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Platzhalter für E-Mails</strong>
            </slot>
          </CCardHeader>
          <CCardBody>
            <table class="vars-to-use" border="0" v-pre>
              <tbody>
                <tr>
                  <td>{{MAKLER}}</td>
                  <td>Ihr Maklername</td>
                </tr>
                <tr>
                  <td>{{IMMOBILIEN_TYP}}</td>
                  <td>Z.b. Haus, Eigentumswohnung etc.</td>
                </tr>
                <tr>
                  <td>{{ANREDE}}</td>
                  <td>Anrede </td>
                </tr>
                <tr>
                  <td>{{NACHNAME}}</td>
                  <td>Nachname des Eigentümers</td>
                </tr>
                <tr>
                  <td>{{VORNAME}}</td>
                  <td>Vorname des Eigentümers</td>
                </tr>
                <tr>
                  <td>{{TELEFON}}</td>
                  <td>Telefonnummer des Eigentümers</td>
                </tr>
                <tr>
                  <td>{{EMAIL}}</td>
                  <td>E-Mail Adresse des Eigentümers</td>
                </tr>
                <tr>
                  <td>{{ADRESSE}}</td>
                  <td>Vollständige Anschrift der Immobilie</td>
                </tr>
                <tr>
                  <td>{{WOHNFLACHE}}</td>
                  <td>Wohnfläche der Immobilie</td>
                </tr>
                <tr>
                  <td>{{ZIMMER}}</td>
                  <td>Anzahl der Zimmer der Immobilie</td>
                </tr>
                <tr>
                  <td>{{BAUJAHR}}</td>
                  <td>Baujahr der Immobilie</td>
                </tr>
                <tr>
                  <td>{{GRUNDFLACHE}}</td>
                  <td>Grundstücksfläche der Immobilie</td>
                </tr>
                <tr>
                  <td>{{ETAGE}}</td>
                  <td>Anzahl der Etagen der Immobilie</td>
                </tr>
                <tr>
                  <td>{{ERSCHLOSSEN}}</td>
                  <td>Bei Grundstücken, angabe ob Erschlossen, Teilerschlossen oder nicht erschlossen</td>
                </tr>
                <tr>
                  <td>{{BEBAUUNG}}</td>
                  <td>Bebaungsmöglichkeiten von Grundstücken</td>
                </tr>
                <tr>
                  <td>{{ZUSCHNITT}}</td>
                  <td>Angaben zum Grundstückszuschnitt</td>
                </tr>
                <tr>
                  <td>{{GRUND}}</td>
                  <td>Grund der Immobilienbewertung Kauf oder Verkauf</td>
                </tr>
                <tr>
                  <td>{{GRUND_WANN}}</td>
                  <td>Verkaufszeitwunsch, wenn "Verkauf" als Grund ausgewählt</td>
                </tr>
                <tr>
                  <td>{{GESAMT_ERGEBNIS}}</td>
                  <td>Ermittelter durchschnittlicher Marktwert</td>
                </tr>
                <tr>
                  <td>{{MIN_GESAMT_ERGEBNIS}}</td>
                  <td>Resultierende Wertspanne minimum</td>
                </tr>
                <tr>
                  <td>{{MAX_GESAMT_ERGEBNIS}}</td>
                  <td>Resultierende Wertspanne maximum</td>
                </tr>
                <tr>
                  <td>{{ERGEBNIS_PRO_QM}}</td>
                  <td>Durchschnittlicher Wert pro m² Wohnfläche</td>
                </tr>
                <tr>
                  <td>{{MIN_ERGEBNIS_PRO_QM}}</td>
                  <td>Ermittelte Wertspanne pro m² minimum</td>
                </tr>
                <tr>
                  <td>{{MAX_ERGEBNIS_PRO_QM}} </td>
                  <td>Ermittelte Wertspanne pro m² maximum</td>
                </tr>
                <tr>
                  <td>{{PDF_URL}} </td>
                  <td>Link zur ursprünglichen Wohnmarktanalyse, falls vorhanden.</td>
                </tr>
              </tbody>
            </table>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'
import NewsletterArticleForm from "@/views/base/newsletter/NewsletterArticleForm";

export default {
  name: 'EditArticle',
  components: { NewsletterArticleForm },
  data() {
    return {
      ready: false,
      article: null,
      processing: false
    }
  },
  created() {
    axios.get('/newsletter/article/' + this.$route.params.id)
        .then(response => {
          let data = response.data
          data.message = JSON.parse(data.message)
          this.article = data
          this.ready = true
        }).catch(err => console.error(err))
  },
  beforeRouteLeave (to, from, next) {
    if (to.params.saved) {
      next()
      return
    }
    this.$modal.showModal(
        'default',
        'Editor wirklich schließen?',
        [
          `Es gibt noch Änderungen im Editor, die nicht gespeichert wurden und mit dieser Aktion verloren gehen.`
        ],
        () => {
          next()
        },
        () => {
          next(false)
        },
        { btnCancel: 'abbrechen', btnConfirm: 'Trotzdem schließen', btnConfirmColor: 'danger' }
    )
  },
  methods: {
    editArticle(data) {
      this.processing = true
      axios.put('/newsletter/article/' + this.$route.params.id, data)
          .then(() => {
            this.$router.push({name: 'NewsletterArticles', params: { saved: '1' }})
            this.$toaster.makeToast('Erfolgreich!', "<b>Änderungen wurden gespeichert</b>")
          })
          .catch(err => {
            if (err.response) {
              this.$toaster.makeToast('Oops!', '<b>' + err.response.data.code + '</b><br>' + err.response.data.message)
            } else {
              this.$toaster.makeToast('Fehler!', err.message)
            }
          })
          .finally(() => {
            this.processing = false
          })
    },
    sendTestEmail(data) {
      axios.put('/newsletter/article/' + this.$route.params.id + '/testEmail', data)
          .then(() => {
            this.$toaster.makeToast('Success!', "<b>Test E-Mail</b> wurde erfolgreich gesendet!")
          })
          .catch(err => {
            if (err.response) {
              this.$toaster.makeToast('Oops!', '<b>' + err.response.data.code + '</b><br>' + err.response.data.message)
            } else {
              this.$toaster.makeToast('Fehler!', err.message)
            }
          })
          .finally(() => {
            this.processing = false
          })
    }
  }
}
</script>
